export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [2];

export const dictionary = {
		"/(landing)": [10,[3]],
		"/api/[key]": [21],
		"/(landing)/documentation": [11,[3]],
		"/(landing)/f2p": [12,[3]],
		"/(landing)/features": [13,[3]],
		"/(admin)/gamemaster": [4,[2]],
		"/(admin)/gamemaster/agents": [5,[2]],
		"/(admin)/gamemaster/apps": [~6,[2]],
		"/(admin)/gamemaster/modules": [7,[2]],
		"/(admin)/gamemaster/rules": [8,[2]],
		"/(admin)/gamemaster/settings": [9,[2]],
		"/(landing)/health_check": [14,[3]],
		"/(landing)/learn": [15,[3]],
		"/(landing)/prices": [16,[3]],
		"/(landing)/projects": [17,[3]],
		"/(landing)/sign_in": [18,[3]],
		"/(landing)/sign_out": [19,[3]],
		"/(landing)/sign_up": [20,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';